import { ForwardedRef, useCallback, useMemo, useRef, useState } from 'react'
import { HotelResults, HotelResultsSchema } from '@reward-platform/ancillaries-schemas/hotel'
import {
  Location,
  LocationDataTransferObjects,
} from '@reward-platform/ancillaries-schemas/location'
import { useClickAway } from '@reward-platform/lift/hooks/useClickAway'
import { formatValidationMessage, getValidationErrorMessage } from '@reward-platform/utils'
import { addDays, format, min, addYears, add } from 'date-fns'
import { useRouter } from 'next/router'
import { stringify } from 'qs'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQuery } from 'react-query'
import { ZodIssueCode } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNotification } from '~/components/shared/Notifications'
import useDebounce from '~/hooks/useDebounce/useDebounce'
import { getLocations } from '~/services/locationService'
import { usePartner } from '~/context/partner'
import { useExperimentFlag } from '~/hooks/useFeatureFlag/useExperimentFlag'
import { adjustDateObjectTimeToUserTime } from '~/utils/datetime'
import { addSearchToLocalStorage } from '../RecentSearches/RecentSearches'
import { PopularDestinationType } from '../PopularDestinations/PopularDestinations'

const SEARCH_TYPE = 'HOTEL'

/**
 * Set up and manage state for the Destination autocomplete field
 */
export function useDestinationState(
  form: UseFormReturn<HotelResults>,
  defaultState: HotelResults['destination']
) {
  const { code: partner } = usePartner()
  const fieldName: keyof HotelResults = 'destination'
  const { addNotification } = useNotification()
  const [searchString, setSearchString] = useState<string>(defaultState?.fullName || '')
  const fieldValue = form.getValues(fieldName)

  const debouncedSearchString = useDebounce(searchString, 500)

  // When re-focusing the input field, pause re-fetching until user edits the query and instead show previous data.
  const querySameAsFullName = searchString === fieldValue.fullName
  const canSearch =
    debouncedSearchString !== '' && debouncedSearchString === searchString && !querySameAsFullName

  const hotelOrderingExperimentFlag = useExperimentFlag('hotel-location-search-ordering')

  const { data: locations, isLoading } = useQuery(
    ['locations', partner, debouncedSearchString],
    () =>
      getLocations(partner, SEARCH_TYPE, debouncedSearchString, hotelOrderingExperimentFlag.value),
    {
      enabled: canSearch,
      keepPreviousData: true,
      onError: (e: Error) => addNotification(e.message),
    }
  )

  const noResultsFound = locations?.length === 0 && !isLoading && canSearch

  const onInputFocus = useCallback(
    (e: { target: { select: () => void } }, openMenu: () => void) => {
      e.target.select()
      setSearchString(fieldValue.fullName)
      openMenu()
    },
    [fieldValue.fullName]
  )

  const handleAutocompleteChange = useCallback(
    (value: string) => {
      setSearchString(value)
      if (value.length === 0) {
        form.setValue(
          fieldName,
          { id: '', name: '', type: '', fullName: '' },
          { shouldDirty: true }
        )
      }
    },
    [form]
  )

  const handleSuggestionClick = useCallback(
    (key: string) => {
      const location = (locations as LocationDataTransferObjects).find(({ id }) => id === key)
      if (location) {
        setSearchString(location.fullName)
        form.clearErrors(fieldName)
        form.setValue(fieldName, location, { shouldDirty: true })
      }
    },
    [locations, form, fieldName]
  )

  const handleDestinationClick = async (destination: PopularDestinationType) => {
    const location: Location = {
      countryCode: destination.countryCode,
      fullName: destination.fullName,
      id: destination.id,
      name: destination.name,
      type: destination.type,
      vendorBrandId: destination.vendorBrandId,
    }

    setSearchString(destination.fullName)
    form.clearErrors(fieldName)
    form.setValue(fieldName, location, { shouldDirty: true })
  }

  return {
    isLoading,
    locations: isLoading || !canSearch || !locations ? [] : locations,
    ...fieldValue,
    searchString,
    setSearchString,
    noResultsFound,
    onInputFocus,
    handleAutocompleteChange,
    handleSuggestionClick,
    handleDestinationClick,
  }
}

/**
 * Set up and manage the state for the date range fields
 */
export function useDateRangeState(
  form: UseFormReturn<HotelResults>,
  [defaultStartDate, defaultEndDate]: [Date, Date]
) {
  const maxStartDate = useMemo(() => new Date(addYears(Date.now(), 1)), [])
  const maxPossibleEndDate = useMemo(() => new Date(add(Date.now(), { years: 1, days: 28 })), [])
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState<Date | null>(defaultEndDate)
  const [maxDate, setMaxDate] = useState(maxStartDate)
  const [showCalendar, setShowCalendar] = useState(false)
  const calendarRef = useRef<HTMLInputElement | null>(null)

  const startDatePlusMaxDuration = addDays(startDate, 28)

  useClickAway(calendarRef, showCalendar, () => setShowCalendar(false))

  const handleDateChange = useCallback(
    (dates: [Date | null, Date | null]) => {
      if (!dates || !Array.isArray(dates)) {
        return
      }
      const [start, end] = dates
      if (!start) {
        return
      }

      const adjustedStart = adjustDateObjectTimeToUserTime(start, 28)

      setStartDate(adjustedStart)
      form.setValue('startDateTime', adjustedStart)

      // dynamically set max date
      if (end === null) {
        if (startDatePlusMaxDuration > maxPossibleEndDate) {
          setMaxDate(maxPossibleEndDate) // 500 days from current date
        } else {
          setMaxDate(startDatePlusMaxDuration) // 28 days from startDate
        }
      } else {
        setMaxDate(maxStartDate) // 499 days from current date
      }

      if (end?.getTime() === adjustedStart.getTime()) {
        end.setDate(adjustedStart.getDate() + 1)
      }

      setEndDate(end)
      const formEndValue = end as unknown as Date
      form.setValue('endDateTime', formEndValue, { shouldValidate: !!end })

      if (end != null) {
        setShowCalendar(false)
      }
    },
    [form, maxPossibleEndDate, maxStartDate, startDatePlusMaxDuration]
  )

  const minDate = useMemo(() => {
    const from = new Date()
    from.setHours(from.getHours() + 28)
    return from
  }, [])

  const maxEndDate = min([startDatePlusMaxDuration, maxPossibleEndDate])

  return {
    startDate,
    endDate,
    minDate,
    maxDate,
    maxEndDate,
    showCalendar,
    setShowCalendar,
    calendarRef,
    handleDateChange,
  }
}

/**
 * Manages data transformations relating to the traveller room picker
 * TODO: This needs to be updated to manage the state for the field, when the field supports it
 */
export function useTravellerPickerState(
  form: UseFormReturn<HotelResults>,
  defaultState: HotelResults['rooms']
) {
  const rooms = form.getValues('rooms')
  const travellerCount = useMemo(
    () =>
      rooms.reduce(
        (acc, room) => {
          acc.adultCount += room.adults
          acc.childCount += room.children
          return acc
        },
        {
          adultCount: 0,
          childCount: 0,
        }
      ),
    [rooms]
  )

  return {
    ...travellerCount,
    roomCount: rooms.length,
  }
}

/**
 * Automatically converts the zod validation errors to a translated human readable error message
 */
function useHotelSearchErrors(errors: UseFormReturn<HotelResults>['formState']['errors']) {
  const intl = useIntl()
  const { notAValid, mustBeSelected, cannotBeEmpty } = formatValidationMessage(intl)

  const t = useMemo(
    () => ({
      searchString: intl.formatMessage({ id: 'search', defaultMessage: 'search string' }),
      searchType: intl.formatMessage({ id: 'searchType', defaultMessage: 'search type' }),
      startDateTime: intl.formatMessage({ id: 'startDateTime', defaultMessage: 'start date' }),
      endDateTime: intl.formatMessage({ id: 'endDateTime', defaultMessage: 'end date' }),
      destination: intl.formatMessage({ id: 'destination', defaultMessage: 'Destination' }),
      room: intl.formatMessage({ id: 'room', defaultMessage: 'Room' }),
    }),
    [intl]
  )

  const errorMap = useMemo(
    () => ({
      searchString: { [ZodIssueCode.custom]: notAValid(t.searchString) },
      startDateTime: { [ZodIssueCode.custom]: notAValid(t.startDateTime) },
      endDateTime: {
        [ZodIssueCode.custom]: notAValid(t.endDateTime),
        [ZodIssueCode.invalid_type]: cannotBeEmpty(),
      },
      destination: { [ZodIssueCode.custom]: mustBeSelected(t.destination) },
      rooms: { [ZodIssueCode.too_small]: mustBeSelected(t.room) },
    }),
    [t, notAValid, mustBeSelected, cannotBeEmpty]
  )

  // Returns standard error regardless of specific nested errors
  const getDestinationError = useCallback(
    () => errors?.destination && errorMap.destination[ZodIssueCode.custom],
    [errors, errorMap]
  )

  const getFieldError = useCallback(
    (field: keyof typeof errorMap) =>
      errors?.[field] &&
      getValidationErrorMessage({
        errorMap,
        errors,
        field,
        intl,
      }),
    [errors, intl, errorMap]
  )

  return {
    getFieldError,
    getDestinationError,
  }
}

/**
 * Sets up the react-hook-form and creates the submit handler and error translations
 * @param defaultValues The default values for the form state
 */
export function useHotelSearchForm(
  defaultValues: HotelResults,
  toggleRef?: ForwardedRef<HTMLButtonElement>
) {
  const { push } = useRouter()
  const form = useForm<HotelResults>({
    defaultValues,
    resolver: zodResolver(HotelResultsSchema),
  })
  const { getValues } = form
  const errors = useHotelSearchErrors(form.formState.errors)

  // this should validate date from startTime etc

  const handleFormSubmit = useCallback(() => {
    const { startDateTime, endDateTime, ...values } = getValues()

    const searchData = {
      ...values,
      searchType: SEARCH_TYPE,
      dates: {
        startDateTime: format(startDateTime, "y-MM-dd'T'HH:mm:ss"),
        endDateTime: endDateTime && format(endDateTime, "y-MM-dd'T'HH:mm:ss"),
      },
      page: 1, // this is always a new search
    }

    if (toggleRef && typeof toggleRef !== 'function') {
      toggleRef?.current?.click()
    }

    addSearchToLocalStorage(searchData)

    push({
      pathname: '/hotels/results',
      query: stringify(searchData),
    })
  }, [getValues, toggleRef, push])

  return {
    form,
    handleFormSubmit,
    errors,
  }
}
