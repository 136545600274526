import { z } from 'zod'
import { CurrencyCodesSchema } from '../../country-currency-codes'
import {
  PaginationSchema,
  PriceLadderSchema,
  RoomSchema,
  PopularAmenityIconSchema,
  WarningSchema,
} from '../../common'

const LatLongLocation = z.object({
  latitude: z.number().optional(),
  longitude: z.number().optional(),
})

const HotelSearchResultSchema = z.object({
  bundleId: z.string(),
  vendorName: z.string(),
  propertyLocation: LatLongLocation,
  rooms: z.array(RoomSchema),
  vendorId: z.string(),
  imageUrl: z.string().optional(),
  starRating: z.number(),
  guestRating: z.number(),
  guestRatingCount: z.number(),
  aviosPrice: z.number(),
  cashPrice: z.number(),
  collectAmount: z.number(),
  currencyCode: CurrencyCodesSchema,
  pricingLadder: z.array(PriceLadderSchema),
  popularAmenities: z.array(PopularAmenityIconSchema),
  category: z.string().optional(),
  brand: z.string().optional(),
})

export const HotelSearchDataTransferObjectSchema = z.object({
  correlationId: z.string(),
  recommendationId: z.string(),
  results: z.array(HotelSearchResultSchema),
  pagination: PaginationSchema,
  warnings: z.array(WarningSchema).optional(),
})

export type HotelSearchResult = z.infer<typeof HotelSearchResultSchema>
export type HotelSearchDataTransferObject = z.infer<typeof HotelSearchDataTransferObjectSchema>
