import { z } from 'zod'
import { AddressSchema } from '../address'
import {
  AmenitiesGroupSchema,
  CheckInDetailsSchema,
  RoomSchema,
  RatingBreakdownSchema,
  PopularAmenityIconSchema,
  TravelerSchema,
} from '../../common'

const CustomerReviewSchema = z.object({
  description: z.string().optional(),
  name: z.string(),
  date: z.string(),
  overallGuestRating: z.number(),
  customerReviewVendor: z.string(),
})

const InformationSchema = z.object({
  languages: z.array(z.string()),
  general: z.array(z.string()).optional(),
  onsitePayments: z.array(z.string()).optional(),
  pets: z.array(z.string()).optional(),
  amenities: z.object({}).catchall(z.array(z.string())),
  location: z.string().optional(),
  attraction: z.string().optional(),
  checkInDetails: CheckInDetailsSchema,
  fees: z
    .object({
      mandatory: z.array(z.string()).optional(),
      optional: z.array(z.string()).optional(),
    })
    .optional(),
  policies: z
    .object({ knowBeforeYouGo: z.array(z.string()).optional(), renovation: z.string().optional() })
    .optional(),
})

const HotelDetailsResultSchema = z.object({
  bundleId: z.string(),
  vendorName: z.string(),
  vendorId: z.string(),
  media: z.array(
    z.object({
      title: z.string().optional(),
      mediaSequence: z.number(),
      mediaList: z.array(
        z.object({
          type: z.string(),
          method: z.string(),
          href: z.string(),
        })
      ),
    })
  ),
  propertyLocation: z
    .object({
      latitude: z.number().optional(),
      longitude: z.number().optional(),
    })
    .optional(),
  rooms: z.array(RoomSchema),
  category: z.string().optional(),
  brand: z.string().optional(),
  policies: z.array(AmenitiesGroupSchema),
  propertyAmenities: z.array(AmenitiesGroupSchema),
  customerReviews: z.array(CustomerReviewSchema),
  information: InformationSchema.optional(),
  starRating: z.number(),
  guestRating: z.number(),
  guestRatingCount: z.number(),
  ratingBreakdown: RatingBreakdownSchema.optional(),
  address: AddressSchema,
  popularAmenities: z.array(PopularAmenityIconSchema),
  travelers: z.array(TravelerSchema),
  aviosPrice: z.number(),
  cashPrice: z.number(),
  collectAmount: z.number(),
})

export const HotelDetailsDataTransferObjectSchema = z.object({
  correlationId: z.string(),
  recommendationId: z.string(),
  dates: z.object({
    startDate: z.string(),
    endDate: z.string(),
  }),
  result: HotelDetailsResultSchema,
})

export type HotelDetailsResult = z.infer<typeof HotelDetailsResultSchema>
export type HotelDetailsDataTransferObject = z.infer<typeof HotelDetailsDataTransferObjectSchema>
